<template>
    <div class="SurplusEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            ref="editForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            @submit.native.prevent
            size="small"
            :inline="true"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="补货机构">
                            <el-input v-model="form.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="form.createTime" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                    查询并添加
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableShowPickUpGoodsDialog"
                    @click="showPickUpGoods"
                    size="small"
                >
                    选择商品基本信息
                </el-button>
                <el-button type="primary" :loading="saveLoading" @click="handleSave" size="small">保存</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    v-loading="tableLoading"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="150">
                        <template slot-scope="scope">
                            {{ (skuByGoodsCode(scope.row.goodsCode).bars || []).map((d) => d).join(' / ') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).customBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="实际补货数量" width="170">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.repayGoodsCount'"
                                :rules="rules.repayGoodsCount"
                            >
                                <el-input-number
                                    :controls="false"
                                    v-model="scope.row.repayGoodsCount"
                                    size="mini"
                                    :precision="0"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickupGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanEqualZeroValidateRule } from 'js/validate/ValidateStockBizRule';
import GoodsCommon from 'js/GoodsCommon';
export default {
    name: 'ReplenishmentEdit',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    components: { PickUpGoods, EfRemark },
    data() {
        return {
            search: '',
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                remark: '',
                detailParams: [],
                creator: '',
                createTime: '',
            },

            //所选商品详情列表
            detailParamsGoodsDetailMap: new Map(),

            rules: {
                deptCode: deptCodeValidateRule(),
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                repayGoodsCount: [countGreaterThanEqualZeroValidateRule()],
            },
            tableLoading: true,
            saveLoading: false,
            url: {
                queryGoods: '/goods/goods/list_c',
            },
        };
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    mounted() {
        (async () => {
            this.code = this.$router.history.current.params.code;
            let rst = await this.$http.get('/repayDeptGoods/bizDetail/' + this.code, null);
            rst = (rst || {}).data.data;
            Util.copyProperties(rst, this.form);
            this.form.detailParams = rst.details;
            const goodsArr = await this.$efApi.goodsApi.goodsListByCodes(
                this.form.detailParams.map((e) => e.goodsCode)
            );
            const goodsMap = new Map();
            goodsArr.forEach((e) => {
                goodsMap.set(e.code, e);
            });
            this.detailParamsGoodsDetailMap = goodsMap;
            this.tableLoading = false;
        })();
    },
    methods: {
        setSelectedGoods(selectedGoods) {
            selectedGoods.forEach((goods) => {
                if (!this.detailParamsGoodsDetailMap.get(goods.code)) {
                    this.detailParamsGoodsDetailMap.set(goods.code, goods);
                    this.form.detailParams.push({ goodsCode: goods.code, repayGoodsCount: undefined });
                }
            });
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                this.saveLoading = true;
                this.$http
                    .post(
                        `/repayDeptGoods/editExtend?code=${this.form.code}&remark=${this.form.remark || ''}`,
                        this.form.detailParams
                    )
                    .then((rst) => {
                        this.saveLoading = false;
                        this.goBackAndReload();
                    })
                    .catch((rst) => {
                        this.saveLoading = false;
                    });
            });
        },
        handleDelete(index) {
            const deleted = this.form.detailParams.splice(index, 1);
            (deleted || []).forEach((e) => this.detailParamsGoodsDetailMap.delete(e.goodsCode));
        },
        goodsByCode(goodsCode) {
            return this.detailParamsGoodsDetailMap.get(goodsCode) || {};
        },
        skuByGoodsCode(goodsCode) {
            return (this.goodsByCode(goodsCode) || {}).sku || {};
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>
